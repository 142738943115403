import type { MenuProps } from "antd";

export const teams: MenuProps["items"] = [
  {
    label: "América SE",
    key: "1",
  },
  {
    label: "Atlético Gloriense",
    key: "2",
  },
  {
    label: "Estanciano",
    key: "3",
  },
  {
    label: "Falcon",
    key: "4",
  },
  {
    label: "Frei Paulistano",
    key: "5",
  },
  {
    label: "Itabaiana",
    key: "6",
  },
  {
    label: "Lagartense",
    key: "7",
  },
  {
    label: "Dorense",
    key: "8",
  },
  {
    label: "Sergipe",
    key: "9",
  },
  {
    label: "Confiança",
    key: "0",
  },
];

export const teamsID = {
  confianca: "0",
  americaSe: "1",
  atleticoGloriense: "2",
  estanciano: "3",
  falcon: "4",
  freiPaulistano: "5",
  itabaiana: "6",
  lagarto: "7",
  dorense: "8",
  sergipe: "9",
};

interface Product {
  key: string;
  token: string;
  name: string;
  oldPrice?: number;
  price: number;
  oldPriceInstallment?: number;
  priceInstallment?: number;
  description: string;
  image: string;
  background: string;
}

export const products: Product[] = [
  // {
  //   key: "1",
  //   token: "63a600abd7044d25a4f9872f",
  //   name: "Campeonato Sergipano 2023",
  //   oldPrice: 89.7,
  //   price: 39.7,
  //   // oldPriceInstallment: 9.09,
  //   // priceInstallment: 7.27,
  //   description:
  //     "Curta os jogos do seu time do coração no campeonato sergipano de 2023",
  //   image: "semifinaisFinais",
  //   background: "#46009a",
  // },
  // {
  //   key: "2",
  //   token: "63cbf262c2f7b273b961e8ae",
  //   name: "Copa Alagoas 2023",
  //   price: 0,
  //   description: "Novidade! Acompanhe o Campeonato Alagoano 2023 na ITTV!",
  //   image: "alagoano",
  //   background: "#3b7973",
  // },
  {
    key: "1",
    token: "63e157b7d7044d25a4289e46",
    name: "Itabaiana X Confiança",
    price: 9.9,
    description: "Garanta agora o ingresso para Itabaiana X Confiança",
    image: "itaXcon",
    background: "#46009a", //"#0094DB"
  },
];
//        image: '../../images/products/sergipao.png',
//   image: '../../images/products/confiancaXfalcon.png',

export const redirectProducts: IRedirectProducts[] = [
  {
    key: "1",
    name: "Smart Abertos",
    image: "abertos30channel",
    price: 9.9,
    path: "https://tv.ittv.com.br/auth/signup",
    background: "#0094DB",
  },
/*   {
    key: "3",
    name: "Smart Abertos + Brasileirão Série C",
    image: "total30channel",
    price: 39.9,
    path: "https://tv.ittv.com.br/auth/signup",
    background: "#46009a",
  },
  {
    key: "4",
    name: "Smart Mini + Brasileirão Série C",
    image: "totalCanais",
    price: 49.9,
    path: "https://tv.ittv.com.br/auth/signup",
    background: "#0094DB",
  },
  {
    key: "5",
    name: "Smart Plus + Brasileirão Série C",
    image: "totalCopaNordeste",
    price: 79.9,
    path: "https://tv.ittv.com.br/auth/signup",
    background: "#8a2587",
  }, */
];

export interface IRedirectProducts {
  key: string;
  name: string;
  image: string;
  price: number;
  path: string;
  background: string;
}

export const states = [
  {
    key: "1",
    label: "Acre",
    region: "Norte",
  },
  {
    key: "2",
    label: "Alagoas",
    region: "Nordeste",
  },
  {
    key: "3",
    label: "Amapá",
    region: "Norte",
  },
  {
    key: "4",
    label: "Amazonas",
    region: "Norte",
  },
  {
    key: "5",
    label: "Bahia",
    region: "Nordeste",
  },
  {
    key: "6",
    label: "Ceará",
    region: "Nordeste",
  },
  {
    key: "7",
    label: "Distrito Federal",
    region: "Centro-Oeste",
  },
  {
    key: "8",
    label: "Espírito Santo",
    region: "Sudeste",
  },
  {
    key: "9",
    label: "Goiás",
    region: "Centro-Oeste",
  },
  {
    key: "10",
    label: "Maranhão",
    region: "Nordeste",
  },
  {
    key: "11",
    label: "Mato Grosso",
    region: "Centro-Oeste",
  },
  {
    key: "12",
    label: "Mato Grosso do Sul",
    region: "Centro-Oeste",
  },
  {
    key: "13",
    label: "Minas Gerais",
    region: "Sudeste",
  },
  {
    key: "14",
    label: "Pará",
    region: "Norte",
  },
  {
    key: "15",
    label: "Paraíba",
    region: "Nordeste",
  },
  {
    key: "16",
    label: "Paraná",
    region: "Sul",
  },
  {
    key: "17",
    label: "Pernambuco",
    region: "Nordeste",
  },
  {
    key: "18",
    label: "Piauí",
    region: "Nordeste",
  },
  {
    key: "19",
    label: "Rio de Janeiro",
    region: "Sudeste",
  },
  {
    key: "20",
    label: "Rio Grande do Norte",
    region: "Nordeste",
  },
  {
    key: "21",
    label: "Rio Grande do Sul",
    region: "Sul",
  },
  {
    key: "22",
    label: "Rondônia",
    region: "Norte",
  },
  {
    key: "23",
    label: "Roraima",
    region: "Norte",
  },
  {
    key: "24",
    label: "Santa Catarina",
    region: "Sul",
  },
  {
    key: "25",
    label: "São Paulo",
    region: "Sudeste",
  },
  {
    key: "26",
    label: "Sergipe",
    region: "Nordeste",
  },
  {
    key: "27",
    label: "Tocantins",
    region: "Norte",
  },
];
